import React from "react"
import PropTypes from "prop-types"
const PostHero = ({ image, imageMobile, className }) => {
  return (
    <section className={ `post-hero relative w-full max-w-5xl mx-auto ${className}` } >
      <img src={image.desktop || image.url} className={`img_header desktop w-auto mx-auto`} alt={image.asset_alt || ''} />
      <img src={imageMobile.mobile} className={`img_header mobile w-auto mx-auto`} alt={imageMobile.asset_alt || ''} />
    </section>
  )
}

PostHero.defaultProps = {
  className: '',
}

PostHero.propTypes = {
    image: PropTypes.object.isRequired,
    imageMobile: PropTypes.object.isRequired,
    className: PropTypes.string,
}
  
export default PostHero
