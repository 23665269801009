import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Parser from "html-react-parser";
import Layout from "../components/layout"
import PostHero from "../components/images/postHero"
import NeoPostContent from "../components/neo/content"
import { getPrettyDate, getStandardDate } from "../utils/dates"
import { useTranslation } from "react-i18next"

const isDateDisplayed = true;

export const query = graphql`
	query ($id: [Craft_QueryArgument], $language: [String]) {
		craft {
			entry(id: $id, site: $language) {
				... on Craft_news_news_Entry {
					post_scripts_styles {
						id
						kind
						url
						mimeType
        		extension
					}
          post_content {
              ...PostContentFragment
          }
				}
			}
		}
	}
`

const News = ({ data, pageContext }) => {
	const { t } = useTranslation(`default`)

	const {
				post_content,
				post_scripts_styles,
    } = data.craft.entry
    
	const { 
		title, 
		postDate,
        globalData,
        // post_accentColor,
		post_featureImage,
		post_summary,
		nav_parent,
		nav_previous,
		nav_next,
    } = pageContext

	const post_accentColor = 'no_accent';
	const accentColor = post_accentColor ? post_accentColor.replace('_accent', '') : 'no';

	return (
		<Layout { ...globalData } ressourceAssets={post_scripts_styles} >
			<div className={`page-container title-color-container relative block py-0 px-8 xl:px-0 mx-auto mt-8 mb-16 max-w-5xl ${accentColor}_accent`} >
				{isDateDisplayed && 
					<time
						className="text-xs lg:text-sm text-right block opacity-50 mx-0 my-4"
						dateTime={getStandardDate(postDate)}
					>
						{getPrettyDate(postDate)}
					</time>
				}
				{(post_featureImage && post_featureImage[0]) && 
					<PostHero 
						image={post_featureImage[0]}
						imageMobile={post_featureImage[1] ? post_featureImage[1] : post_featureImage[0] }
					/>
				}
				{title && 
					<section className={`intro-block max-w-5xl m-auto px-0`}>
						<h1 className={`title text-center text-4xl lg:text-5xl my-8`}>{title}</h1>
						{post_summary && Parser(post_summary)}
					</section>
				}
				<NeoPostContent
                    accentColor={accentColor}
                    blocks={post_content} 
                    globProps={globalData.globProps}
                />
				<div className="my-24 text-center">
					<div className="my-12 border-t-2 border-sand flex flex-row flex-no-wrap justify-between w-full" >
						<a href={nav_previous || null} rel="noreferrer" className={`post_nav__button text-2xl text-bold ${!nav_previous && 'text-sand disabled'}`} >{"<"}</a>
						<a href={nav_next || null} rel="noreferrer" className={` post_nav__button text-2xl text-bold ${!nav_next && 'text-sand disabled'}`} >{">"}</a>
					</div>
					{nav_parent && (
						<div className="text-center w-full" >
							<a href={nav_parent} rel="noreferrer" className={`ardene-btn brand-cta font-bold text-sm m-0 link_underline black_underline`}>
								{t(`BACK TO NEWS`)}
							</a>
						</div>
					)}
				</div>
			</div>
		</Layout>
	)
}

News.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
}

export default News